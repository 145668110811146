<template>
    <div class="alert">
        <a-alert message="" type="success">
            <template #description>
                <p>用户姓名：{{ name }}</p>
                <p>用户手机号：{{ phone }}</p>
                <p>注册日期：{{ data }}</p>
            </template>
        </a-alert>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        data: {
            type: String,
            default: ''
        },
    },
    created() {

    },
    data() {
        return {
        
        };
    },
    methods: {

    },
};
</script>

<style lang="less" scoped>

</style>
