// 用户中心相关接口
import request from '@/request/request'

// 用户列表
export const UserList = (params) => request.post(`/adminv2/usercenter/userList`, params);

// 用户详情
export const UserDetail = (params) => request.post(`/adminv2/usercenter/userDetail`, params);

// 刷新单个用户数据
export const RefreshOne = (params) => request.post(`/adminv2/usercenter/refreshOne`, params);

// 锁定/解锁用户
export const LockUser = (params) => request.post(`/adminv2/usercenter/lockUser`, params);

// 修改单个用户代理归属
export const UserAgentChange = (params) => request.post(`/adminv2/usercenter/userAgentChange`, params);

// 获取最近7天打开app次数
export const OpenCount = (params) => request.post(`/adminv2/usercenter/openCount`, params);

// 课程关键字搜索
export const SearchCourse = (params) => request.post(`/adminv2/usercenter/searchCourse`, params);

//------------------------------------ 归 属 划 分 -------------------------------------------------------------------
// 获取某代理归属的用户列表
export const UserListForCode = (params) => request.post(`/adminv2/usercenter/userListForCode`, params);

// 批量修改代理归属
export const UsersAgentChange = (params) => request.post(`/adminv2/usercenter/usersAgentChange`, params);

// 用户归属批量划分记录
export const BatchList = (params) => request.post(`/adminv2/usercenter/batchList`, params);

// 导出批次关联用户
export const UserListForBatch = (params) => request.post(`/adminv2/usercenter/userListForBatch`, params);

//------------------------------------ 归 属 变 更 -------------------------------------------------------------------
// 用户归属变更查询列表
export const ChangeHistory = (params) => request.post(`/adminv2/usercenter/changeHistory`, params);

//-------------------------------------- 组 建 通 用 -----------------------------------------------------------------
// 获取代理商树
export const AgentTree = (params) => request.post(`/adminv2/agent/agentTree`, params);

// ------------------------------------- 用户播放数据 -------------------------------------------------------------------
// 用户播放数据
export const UserPlayList = (params) => request.post(`/adminv2/playvideo/userPlayList`, params);

//近期学习使用行为
export const UserStudyBehavior = (params) => request.post(`/adminv2/playvideo/userStudyBehavior`, params);

// 学习内容排行
export const StudyRank = (params) => request.post(`/adminv2/playvideo/studyRank`, params);

// 用户课程播放详情
export const UserCoursePlayDetail = (params) => request.post(`/adminv2/playvideo/userCoursePlayDetail`, params);

// 根据类型获取二级分类列表
export const GetSubCateOptionsByType = (params) => request.post(`/adminv2/category/getSubCateOptionsByType`, params);

// 课讲详情
export const UserLessonsPoints = (params) => request.post(`/adminv2/playvideo/userLessonsPoints`, params);
